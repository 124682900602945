import { defineComponent, onMounted } from "vue";
import router from "@/router/index";
export default defineComponent({
    name: "error-500",
    setup: function () {
        onMounted(function () {
            setTimeout(function () {
                router.push("latest");
            }, 5000);
        });
    }
});
